// Some of the animations are from animate.css (https://daneden.github.io/animate.css)

// Fade out
@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
.fadeOut {
    animation-name: fadeOut;
}

@keyframes fadeOutDown {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
}
.fadeOutDown {
    animation-name: fadeOutDown;
}

@keyframes fadeOutUp {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
}
.fadeOutUp {
    animation-name: fadeOutUp;
}

// Fade In
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.fadeIn {
    animation-name: fadeIn;
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.fadeInDown {
    animation-name: fadeInDown;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.fadeInUp {
    animation-name: fadeInUp;
}

/**
 * Vue Transitions
 */

// Fade
.fade-enter-active,
.fade-leave-active {
    transition: opacity $speed $easing;
}
.fade-enter,
.fade-leave-active {
    opacity: 0;
}

// Zoom In
.zoom-in-enter-active,
.zoom-in-leave-active {
    transition: opacity $speed-slow $easing;
    .animation-content,
    .animation-content {
        transition: transform $speed-slow $easing;
    }
}
.zoom-in-enter,
.zoom-in-leave-active {
    opacity: 0;
    .animation-content,
    .animation-content {
        transform: scale(0.95);
    }
}


// Zoom Out
.zoom-out-enter-active,
.zoom-out-leave-active {
    transition: opacity $speed-slow $easing;
    .animation-content,
    .animation-content {
        transition: transform $speed-slow $easing;
    }
}
.zoom-out-enter,
.zoom-out-leave-active {
    opacity: 0;
    .animation-content,
    .animation-content {
        transform: scale(1.05);
    }
}

// Slide
.slide-next-enter-active,
.slide-next-leave-active,
.slide-prev-enter-active,
.slide-prev-leave-active {
    transition: transform $speed-slower cubic-bezier(0.785, 0.135, 0.150, 0.860);
}
.slide-prev-leave-to, .slide-next-enter {
    transform: translate3d(-100%, 0, 0);
    position: absolute;
    width: 100%;
}
.slide-prev-enter, .slide-next-leave-to {
    transform: translate3d(100%, 0, 0);
    position: absolute;
    width: 100%;
}
