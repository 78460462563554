#tooltip-controls {
  &:before {
    box-sizing: border-box;
    border-bottom: 6px solid $black-dark;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    content: ' ';
    display: block;
    height: 6px;
    left: 50%;
    position: absolute;
    margin-left: -6px;
    margin-top: -6px;
    top: 0;
    width: 6px;
    background-color: $black-dark; }
  border-radius: 4px;
  display: none;
  padding: 5px 10px;
  position: absolute; }
.editor-container {
  .editor-label {
    min-width: 3rem;
    min-height: 2rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 .5rem;
    border-right: 1px solid $lighter-grey; }
  #sidebar-controls {
    .fa {
      margin-right: 0; } }
  #sidebar-controls,
  .editor-label {
    .controls {
      ul {
        li {
          &:not(:last-child) {
            margin-right: .25rem; } }
        display: inline-flex; } }
    position: absolute;
    margin-right: .5rem; }
  .ql {
    &-embed-selected {
      border: 1px solid #777; }
    &-container {
      font-family: 'Open Sans', Helvetica, sans-serif;
      font-size: 1.2rem; }
    &-blank {
      &:before {
        font-style: normal;
        padding: 0 2.5rem; }
      min-height: 100%;
      height: inherit;
      overflow-y: inherit;
      padding-bottom: rem(75px); } }
  height: 100%;
  position: relative; }
#show-controls {
  transform: rotate3d(0,0,1, 0deg) scale3d(1, 1, 1);
  transition: transform 150ms ease, border 150ms ease-out;
  &.is-open {
    border: transparent;
    transform: rotate3d(0,0,1, 45deg) scale3d(.75, .75, 1); } }
.custom-placeholder {
  > span {
    color: $default-value; } }
.video-importer {
  &:empty {
    &::before {
      content: attr(data-placeholder); } } }
#dropdownPublish {
  .content {
    > p:first-child {
      margin-top: .25rem; } }
  select {
    min-height: 1.6875rem !important; }
  .select {
    &.is-success {
      select {
        border-color: #23d160 !important;
        color: #23d160 !important; } } }
  .dropdown-divider {
    background-color: $lighter-grey !important; }
  .field:not(.pagination):not(.has-addons) {
    margin-bottom: 1.5rem;
    margin-top: .55rem; }
  .tip {
    margin-top: rem(15px) !important;
    margin-bottom: rem(15px) !important; }
  // left: -100%
  .dropdown-menu {
    min-width: 17.5rem !important; } }

.ql-editor {
  @include mobile {
    p {
      font-size: 1rem !important; } }
  .ql-embed-selected {
    border: none !important;
    cursor: text !important; }
  &:before {
    margin-top: .5rem; }
  & p:first-child {
    margin-top: .25rem; }
  > *:not(img) {
    &:last-child {
      margin-bottom: rem(25px); }
    margin-top: 1.5rem; }
  p,
  h2 {
    color: rgba(0,0,0, .8); }
  h2 {
    & + h3 {
      font-size: 1.5rem !important;
      margin-top: rem(5px) !important; }
    font-size: rem(32px);
    margin-left: rem(-2px) !important;
    line-height: 1.04;
    letter-spacing: -.028rem; }
  p {
    & + p {
      margin-top: rem(10px) !important; }
    & + blockquote {
      margin-top: rem(55px) !important; }
    // font: 300 100%/1.89 'Merriweather', serif !important
    font-size: rem(18.5px);
    letter-spacing: -0.004rem; }
  h2,
  h3 {
    & + p {
      margin-top: .5rem; }
    letter-spacing: -.022rem;
    text-transform: none; }
  h3 {
    font-size: rem(27px) !important;
    margin-left: rem(-1.69px) !important;
    margin-bottom: rem(22px) !important;
    line-height: 1.22;
    letter-spacing: -.022rem;
    color: rgba(0,0,0,.44) !important;
    font-weight: 300 !important; }
  > hr {
    & + * {
      margin-top: 3rem; }
    &:before {
      content: '. . .'; }
    border: none;
    color: #111;
    letter-spacing: 1rem;
    text-align: center; }
  .ql-embed-selected {
    border-color: transparent; }
  img {
    &.is- {
      &pulled-left {
        margin-right: 1rem !important; } }
    &.ql-align {
      &-center {
        display: block !important;
        float: none !important;
        margin: 0 auto !important; }
      &-right {
        text-align: right !important;
        float: right !important; }
      &-justify {
        text-align: justify !important; } }
    display: inline; }
  blockquote {
    & + p {
      margin-top: rem(55px) !important; }
    font: italic 100%/1.89 'Lora', serif !important;
    font-size: rem(28px) !important;
    color: rgba(0,0,0,.6);
    border: none;
    padding: 0;
    padding-left: rem(50px);
    text-align: left; }
  margin: 0 auto;
  padding: 0 1.2rem;
  max-width: rem(740px); }
