.alphabet-tracker-wrapper {
  position: sticky;
  top: 0; }
.alphabet-tracker {
  ul {
    margin: 0;
    margin-top: -.5rem;
    padding-right: .25rem; }
  li {
    list-style: none;
    cursor: pointer; }
  .alphabet {
    &--valid {
      color: $yellow-greenery;
      font-weight: 600;
      cursor: pointer; }
    color: $separate-light-gray;
    padding: 0 .75rem;
    cursor: default; } }

