.order-progress {
  .wizard- {
    &tab-content,
    &card-footer {
      display: none !important; } } }
.order-status {
	.wizard-navigation {
		.wizard-progress-with-circle {
			z-index: 5 !important; } }
	.wizard-nav {
		a {
			z-index: 10 !important; } } }
