$browser-context: 16;

@function rem($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px; }

  @if (unitless($context)) {
    $context: $context * 1px; }

  @return $pixels / $context * 1rem; }
