.b-tabs {
    .tabs {
        margin-bottom: 0;
    }
    .tab-content {
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .tab-item {
            flex-shrink: 0;
            flex-basis: 100%;
        }
    }
    &:not(:last-child) {
        margin-bottom: 1.5rem;
    }
}
