.message-container {
  .message {
    .message-title {
      .subtitle {
        font-size: 90%;
        font-weight: 600; }
      margin-bottom: rem(17px);
      text-transform: uppercase;
      letter-spacing: .5px; }
    .message-body {
      > p {
        width: 100%; }
      padding: 0; }
    .content {
      line-height: 1.5; }
    padding-top: 1rem; }
  border-top: 1px solid $beige;
  border-collapse: collapse; }
