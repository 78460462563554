.dropzone {
  display: flex;
  flex-flow: column wrap;
  border: 0;
  padding: 0;
  .dz-message,
  .dropzone-previews {
    border: 1px dashed lightgrey; }
  .dropzone-previews {
    min-height: rem(300px); }
  .dz-message {
    margin-top: 0.375rem;
    padding: rem(10px) 0;
    display: flex;
    justify-content: center;
    align-items: center; }
  .dz-preview {
    .dz-details {
      .hover-cancel {
        top: 0;
        right: 0;
        padding: 5px 10px;
        position: absolute; }
      display: flex;
      flex-flow: column wrap;
      height: 100%;
      justify-content: center; }
    .dz-remove {
      position: relative;
      z-index: 20; }
    .dz-success-mark , .dz-error-mark {
      top: 60%;
      left: 60%; }
    .dz-success-mark {
      color: $yellow-greenery; }
    .dz-error-mark {
      color: $danger; }
    .dz-error-message {
      background: linear-gradient(to bottom, $danger, darken($danger, 2.5%)); }
    &:hover {
      .dz-error-mark {
        opacity: 0; } }
    margin: rem(15px) rem(25px); }
  .remove-all {
    .button {
      border: 0; }
    position: absolute;
    bottom: 10%;
    right: 4.5%; } }
.modal-card-body {
  overflow: hidden; }
