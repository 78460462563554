//Colors
$gainsboro: rgba(226, 225, 225, 65);
$white-smoke: #f7f7f7;
$dark-gray: rgba(37, 46, 58, .75);
$dark-blue: #003399;
$stroke-gray : lighten($dark-gray, 20%);
$light-gray: #d0d0d0;
$lighter-grey: #f2f2f2;
$earth-gray: #7a7a7a;
$separate-light-gray: #dbdbdb;
$dark-green: #002b17;
$light-dark-green: rgba(0, 43, 23, 0.7);
$greenery: #87B145;
$yellow-greenery: #85ce33;
$light-greenery: lighten($greenery, 10%);
$default: #607d8b;
$default-text: #808080;
$light-text: rgba(0,0,0,.6);
$default-value: #b3b3b1;
$beige: #ceb8a3;
$black-blue: #252e3a;
$black-dark: #111111;
$tropicals-color: #76755D;
$tropicals: linear-gradient(to right, rgba(75, 70, 42, 0.75) 0%, rgba(79, 97, 41, 0.75) 100%);
$tropicals--dark: linear-gradient(to right, rgba(49, 46, 29, 0.75) 0%, rgba(60, 74, 31, 0.75) 100%);
$new-bg: linear-gradient(135deg, rgba(37, 46, 58, .9), hsla(29, 30%, 72%, .5)), linear-gradient(180deg, rgba(37, 46, 58, .8), rgba(37, 46, 58, .8));
$featured-bg: linear-gradient(135deg, #9cf537, #ceb8a3), linear-gradient(180deg, #85ce33, #85ce33);
// Body
$body-bg: #fafafa;
$body-bg-darker: #f3f3f3;
// Borders

// Set your colors
$primary: $light-dark-green;
$primary-invert: findColorInvert($light-dark-green);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (white: ($white, $black), black: ($black, $white), light: ($light, $light-invert), dark: ($dark, $dark-invert), primary: ($yellow-greenery, $primary-invert), info: ($info, $info-invert), success: ($greenery, $success-invert), warning: ($warning, $warning-invert), danger: ($danger, $danger-invert)) !default;

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Brands
$brand-primary: $primary;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$font-family-sans-serif: "Source Sans Pro", sans-serif;
$font-family-serif: "Lora", serif;
$ms-base: 16px,50px;
$ms-ratio: 1.618;
$font-size-base: 16px;
$line-height-base: 1.25;
$title-color: #001e00;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

//dropdown variable
// $dropdown-content-background-color: $white !default
// $dropdown-content-arrow: $link !default
// $dropdown-content-offset: 4px !default
// $dropdown-content-radius: $radius !default
// $dropdown-content-shadow: 0 2px 3px rgba($black, 0.1), 0 0 0 1px rgba($black, 0.1) !default
// $dropdown-content-z: 20 !default

// $dropdown-item-color: $grey-dark !default
// $dropdown-item-hover-color: $black !default
// $dropdown-item-hover-background-color: $background !default
// $dropdown-item-active-color: $primary-invert !default
// $dropdown-item-active-background-color: $primary !default

// $dropdown-divider-background-color: $border !default
