.button {
  padding: .25rem 1.25rem;
  align-items: center;
  transition: all 300ms ease;
  &__control {
    &:not(:last-child) {
      margin-right: .25rem; }
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0 !important; }
  &.is-darkgreen {
    &:hover {
      background-color: $white-smoke;
      border-color: $light-dark-green;
      color: $light-dark-green; }
    background-color: $light-dark-green;
    color: $white-smoke;
    border-color: $light-dark-green; }
  &.is-darkgreen--outline {
    &:hover {
      background-color: $light-dark-green;
      border-color: $light-dark-green;
      color: $white-smoke; }
    background-color: transparent;
    color: $light-dark-green;
    border-color: $light-dark-green; }
  &--small {
    @include touch {
      font-size: 90% !important; }
    font-size: 85% !important; }
  &--round {
    border-radius: rem(25px) !important; }
  &--primary {
    &:hover {
      background-color: $light-dark-green;
      border-color: $light-dark-green;
      color: $white-smoke; }
    background-color: transparent;
    color: $light-dark-green;
    border-color: $light-dark-green; }
  &.is-active,
  &:active {
    box-shadow: inset 0 1px 2px transparent; }
  &.is-outlined {
    &:focus {
      background-color: transparent !important; } }
  &__signin,
  &__signup,
  &__cancel {
    button {
      background-color: rgba(255, 255, 255, 0.85);
      border-radius: rem(25px) !important; } }
  &.has-gradient {
    &.is- {
      &tropicals {
        &:hover {
          background-image: $tropicals--dark; }
        background-image: $tropicals;
        color: $white-smoke; } }
    background-color: transparent !important;
    transition: all 299ms; } }
.cta {
  .button {
    @include mobile {
      min-height: 2.5rem !important; }
    align-items: center;
    min-height: 3rem;
    width: 100%;
    border-radius: 0;
    border: 1px solid #edeff2;
    filter: saturate(130%);
    font-size: 90%;
    font-weight: 400;
    padding: .5rem 1.5rem;
    transition: all .3s; } }
.button {
  &.is-default {
    background-color: $default;
    color: white; } }
.add-to-cart {
  .button {
    &:hover {
      background-color: transparent;
      border-color: $greenery;
      color: $greenery; }
    background-color: $greenery;
    color: $white-smoke; } }

.checkout {
  .button {
    &:hover {
      background-color: transparent;
      border-color: $light-dark-green;
      color: $light-dark-green; }
    background-color: $light-dark-green;
    color: $white-smoke; } }

.place-order {
  .button {
    &:hover {
      background-color: transparent;
      border-color: $light-dark-green;
      color: $light-dark-green; }
    background-color: $light-dark-green;
    color: $white-smoke; } }

.see-order,
.send-email,
.view-invoice,
.download-invoice,
.mark-as-done,
.update-order,
.primary {
  &--icon {
    margin-right: .4875rem !important; }
  .button {
    &:hover {
      background-color: $light-dark-green;
      border-color: $light-dark-green;
      color: $white-smoke; }
    border-radius: rem(25px);
    background-color: transparent;
    color: $light-dark-green;
    border-color: $light-dark-green;
    padding: .5rem 1.25rem;
    font-size: 80%;
    align-items: center; } }

.update-order {
  .button {
    &:hover {
      background-color: $earth-gray !important;
      border-color: $earth-gray !important;
      color: $white-smoke !important; }
    background-color: transparent;
    color: $earth-gray !important;
    border-color: $earth-gray !important; } }

.pay-by-ipay {
  .button {
    &:hover {
      background-color: lighten($dark-blue, 5%) !important; }
    background-color: $dark-blue !important;
    color: $white-smoke !important;
    border-color: $dark-gray !important;
    margin-bottom: .25rem; } }

.ipay {
  background-image: url('/images/logo/bualuang_ipay.svg') !important;
  background-size: 70%;
  background-position-y: 5px;
  background-position-x: 50%;
  background-repeat: no-repeat; }
