.lazy {
	&--fadein {
		&[lazy=loaded] {
			@extend .animated;
			@extend .fadeIn;
			// animation:
			// 	name: fadein-half !important
 } } }			// 	duration: 499ms !important

@keyframes fadein-half {
    from {opacity: .5};
    to {opacity: 1}; }
