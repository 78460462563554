.modal {
  .modal-card {
    max-height: calc(100vh - 40px); }
  .modal-card-head {
    border-bottom-color: transparent; }
  .modal-card-foot {
    border-top-color: transparent; }
  .modal-card-body {
    max-height: calc(70vh - 40px);
    overflow-y: auto; }
  .modal-card-foot {
    justify-content: flex-end; } }

.product-quickview-modal {
  .modal-content {
    width: rem(940px); }
  .box {
    border-radius: 0;
    background-image: linear-gradient(341deg, #ece9e6, #fff); }
  .table {
    background-color: transparent; }
  .quick-view-header {
    .product-title {
      margin-right: 1rem; }
    .user-actions {
      .icon {
        cursor: pointer; }
      margin-top: rem(3px); }
    display: inline-flex; }
  .price-title {
    line-height: rem(30px); }
  .message-title .subtitle,
  .table {
    color: $tropicals-color !important; }
  .message-container {
    border: none; }
  .message {
    .message-body {
      .message-title {
        text-transform: capitalize; }
      border: none;
      padding-left: 0;
      padding-right: 0; }
    margin-bottom: 0;
    background-color: transparent; }
  .product-title,
  .price, {
    .title,
    .icon {
      color: $dark-green; } }
  .price-detail-title,
  .th-wrap,
  .message-title,
  .product-checker .label {
    color: $earth-gray; }
  .product-title {
    .title {
      letter-spacing: 2px; } }
  .level-item {
    justify-content: flex-start; }
  .price-checker-container {
    .message {
      padding-top: 0; } }
  .product-checker {
    margin-bottom: .55rem; }
  .price {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 1.25rem;
    .subtitle {
      &:not(:last-child) {
        margin-right: .6rem; }
      font-size: rem(20px);
      line-height: rem(24px);
      margin-bottom: .1rem; } }
  .full-page-link {
    text-decoration: underline;
    font-size: .85rem;
    align-self: flex-end; }
  .product-checker {
    .label {
      font-weight: 400 !important; } }
  .add-to-cart {
    margin: 1rem 0; }
  .icon {
    color: lighten($stroke-gray, 10%);
    margin: 0 -.1rem 0 -.5rem; }
  .content {
    &.content__modal {
      padding: 0 .5rem;
      height: 100%; }
    .b-table {
      font-size: 90%;
      th,
      td {
        > span {
          display: flex;
          align-items: center;
          justify-content: center; }
        text-align: center;
        vertical-align: middle; }
      .th-wrap {
        .icon {
          display: none; }
        justify-content: center;
        font-weight: 400; }
      tr {
        padding: .75rem 1rem; }
      td {
        > span {
          color: $stroke-gray; }
        padding: .2rem .2rem; } } } }

.announcement-modal {
  .announcement-message {
    .content {
      img {
        max-height: fit-content !important;
        margin-bottom: 0.5714rem; }
      p {
        text-transform: none !important; } } } }
