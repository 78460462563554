.page-checkout {
  &-address {
    td {
      @include mobile {
        &:before {
          margin-top: .5rem;
          margin-bottom: 1rem;
          letter-spacing: 1px; }
        .address-info {
          justify-content: flex-end; }
        flex-flow: column wrap; }
      align-items: flex-end;
      position: relative; } }
  .checkout-order-summary {
    article {
      .sub-order-details {
        .cart-note-modal {
          .level-item {
            .icon {
              margin-left: 0; }
            justify-content: flex-start; }
          padding: 1rem 0; }
        flex: 1; }
      display: flex;
      flex-flow: column wrap;
      justify-content: space-between; }
    .cart-total-detail {
      @include mobile {
        margin-bottom: 5rem; } }
    .place-order {
      margin-bottom: 1rem; } }
  .icon__check > div {
      width: 25%;
      height: 25%;
      margin: 0 auto;
      cursor: default; }
  .cart-tile {
    font-size: 80%; }
  .message-container {
    border-color: transparent; }
  .message {
    .message-body {
      padding: 1rem; }
    padding-top: 0; }
  .b-table {
    .table-wrapper {
      margin-top: 1rem; }
    tr {
      &.is-selected {
        color: $yellow-greenery !important;
        background-color: $white; }
      cursor: pointer; }
    .address-info {
      &.contact {
        position: relative; }
      padding: .25rem .5rem; }
    .icon__fav > div {
      @include mobile {
        top: -5px; }
      width: rem(64px);
      height: rem(64px);
      position: absolute;
      top: 0;
      right: 0;
      padding: rem(5px);
      pointer-events: none; } } }
