$main-color: #f2f2f2;
$bg-color: rgba(0, 43, 23, 0.24);
$normal-bg: "/images/signin-bg-1.jpg";
$blurred-bg: "/images/signin-bg-1-blurred.jpg";
.sign-in {
  &-link {
    position: absolute;
    bottom: rem(20px); } }
.signin--full {
  h1 {
    @include mobile {
      padding-left: 2rem; } } }
.modal__ {
  &sign-in {
    .signin--full,
    .signup--full {
      @include touch {
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center; } }
    .modal-background,
    .modal-content {
      background-size: cover !important; }
    .modal-background {
      background: url($blurred-bg) no-repeat center center fixed;
      transform: scale3d(1.3,1.3,1); }
    h1 {
      font-size: 2.5rem !important; }
    .title,
    .label {
      color: $white-smoke !important; }
    .label {
      text-align: left; }
    .modal-content {
      @include mobile {
        width: calc(100vw - 1rem);
        height: calc(100vh - 1rem);
        margin-left: 0 !important;
        margin-right: 0 !important; }
      width: calc(100vw - 5rem);
      height: calc(100vh - 5rem);
      margin: 5rem;
      border-radius: 5px;
      overflow: hidden;
      box-shadow: 0 2px 40px 5px rgba(74, 74, 74, 0.69), 0 0 0 1px rgba(10, 10, 10, 0.1);
      transition: all 1000ms cubic-bezier(0.22, 0.61, 0.36, 1) !important; }
    .box {
      &--darker {
        background-color: rgba(71, 86, 80, 0.7); }
      > .form__container {
        transition: all 400ms ease; }
      background-color: $bg-color;
      margin-bottom: 0;
      height: 100%;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 0 0;
      transition: all 400ms ease; }
    .columns {
      height: 100%; }
    .button {
      &__signup {
        &+ p {
          color: lighten($beige, 10%) !important;
          font-size: 60%;
          font-weight: 400 !important; }
        .button {
          border-radius: 25px; }
        margin-bottom: 1.5rem; } }
    input {
      &::placeholder {
        color: $txt-color; }
      &:focus {
        border-color: lighten($main-color, 5%); }
      &:active {
        border-color: lighten($main-color, 2.5%); }
      border-color: $main-color;
      color: $txt-color;
      transition: all 250ms ease; }
    .fa {
      color: $white-smoke !important; }
    a {
      &:hover {
        color: darken(white, 10%) !important; }
      color: white !important;
      transition: color 300ms ease; }
    .help {
      color: lighten($beige, 10%) !important;
      font-weight: 400 !important;
      font-size: 65%;
      text-transform: none;
      letter-spacing: .5px;
      text-align: center; }
    .help,
    .icon {
      &.is-danger {
        color: white !important;
        font-weight: 600; } }
    input {
      &.is-danger {
        border-color: darken($beige, 50%); } }
    .sign-in-link {
      .link {
        &.is-active {
          color: $yellow-greenery !important; } } }
    .back {
      &__text {
        font-size: 80%; }
      margin-top: 1.5rem; }
    .checkbox {
      &__remember {
        color: $white-smoke;
        font-size: 65%;
        letter-spacing: 1.2px; } } } }
