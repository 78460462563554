$nav-font-size: 14px;
.navbar,
.nl-navigator {
  // &:after
  //   content: ''
  //   position: absolute
  //   width: 95%
  //   height: 25%
  //   left: 0
  //   right: 0
  //   bottom: 0
  //   margin: 0 auto
  //   background-color: rgba(255, 255, 255, 0.90)
  //   box-shadow: -7px 1px 50px 0 rgba(100, 127, 162, 0.3), 1px 1px 30px 0 rgba(131, 173, 84, 0.3)
  &is-fixed-top {
    .navbar-menu {
      @include touch {
        height: calc(100vh - 3.25rem); }
      display: flex;
      justify-content: center;
      align-items: center; }
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0px -5px 10px 2px rgba(51, 51, 51, 0.4);
    background-image: linear-gradient(to right, #f2f2f2 0%, #f2f2f2 100%);
    border: none;
    z-index: 1300; }
  .nav {
    background-color: $white-smoke;
    z-index: 1;
    position: relative;
    .nav-center,
    .nav-control {
      position: relative; }
    .nav-control {
      display: flex;
      justify-content: flex-end;
      & > .navbar-item {
        margin-left: .25rem;
        padding: 0; } }
    .navbar-item {
      color: $text-color;
      font-size: $nav-font-size;
      letter-spacing: .05rem;
      text-decoration: none;
      text-transform: uppercase;
      transition: color 200ms ease-in-out;
      &:hover {
        color: $greenery; }
      &.is-active {
        color: $yellow-greenery; } }
    &.is-active {} }
  &.navbar-desktop /* Style for nav desktop */ {
    @include desktop {
      border: 1px solid #e0e0e0;
      border-left: 0;
      border-right: 0;
      background-color: transparent; } }
  &.navbar-mobile /* Style for nav mobile */ {
    @include touch {
      align-items: center;
      justify-content: space-between;
      .nav-toggle {
        @include tablet-only {
          display: block !important; } }
      .heading {
        margin-bottom: 0; }
      .navbar-menu {
        background-color: $body-bg-darker;
        left: -1px;
        display: none;
        right: -1px;
        top: 100%;
        position: absolute;
        min-height: calc(100vh - 3.375rem);
        .navbar-start {
          & > .navbar-item {
            border-top: 1px solid rgba(219, 219, 219, 0.5);
            padding: 1rem * $ms-ratio 1.25rem;
            justify-content: center; } }
        &.is-active {
          display: block; } } } }
  .icon {
    .fa {
      color: lighten($dark-green, 5%);
      @include mobile {
 }        // font-size: $nav-font-size * $ms-ratio / 1.25 !important
      @include touch {
 } }        // font-size: $nav-font-size * $ms-ratio
    margin: 0; }
  .navigator-item {
    &.open,
    .navigator-item-detail {
      z-index: 4; } }
  position: relative; }

.navbar {
  &-brand {
    .navbar-item {
      padding: 0 .5rem; } }
  &-start {
    @include desktop;
    flex-grow: 1;
    justify-content: space-around; }
  border: solid 1.5px rgba(10, 10, 10, 0.11);
  border-left: 0;
  border-right: 0;
  background-color: $body-bg-darker; }

#nav-navbar__desktop,
#nav-navbar__mobile {
  .navbar {
    &-item {
      @include desktop {
        padding-top: 0.15rem;
        padding-bottom: 0.15rem;
        font-size: 100%;
        font-weight: 600; }
      @include touch {
        padding: 2rem 1.25rem; }
      &.is-active {
        color: $yellow-greenery !important; } } }
  .navbar-item {
    letter-spacing: 1.5px; } }
.dropdown {
  .dropdown-trigger {
    width: 100%; }
  .user-control-trigger {
    @include touch {
      .navbar-item {
        padding-left: 0; }
      display: flex !important;
      align-items: center;
      justify-content: center;
      padding: 0; }
    .fa {
      margin-right: 0; } } }
.navbar {
  &__editor {
    .title {
      line-height: 1.5 !important; }
    justify-content: space-between; } }

.important-information {
  &__menu {
    .navbar-item {
      padding: 0 !important; }
    .dropdown-menu {
      @include touch {
        .dropdown-content {
          padding-left: .25rem !important; }
        text-align: left !important;
        left: 0 !important;
        width: 100% !important; } } } }

