$nav-height: rem(200px);
$body-height: calc(100vh - #{$nav-height});

#bg__app {
	@include touch {
		// display: none !important
		background-image: $tropicals !important; } }
#app {
	&.container {
		max-width: rem(1024px);
		min-height: 100vh;
		margin: 0 auto;
		overflow: hidden; }
	.section-header {
    // min-height: $nav-height
    // max-height: $nav-height + 4.25rem
 }    // z-index: 2
	.section-content {
		@include touch {
			min-height: calc(#{$body-height} - 7rem); }
		min-height: $body-height;
  .page {
    height: 100%; } } }

@include touch {
	#app {
		overflow-x: hidden; } }
