.product-list-page {
  .product-card {
    .image {
      border-radius: rem(3px); }
    .card-content {
      .content {
        @include desktop {
          font-size: 80%; }
        align-items: flex-start; }
      border: none;
      margin: .25rem 0 1rem 0;
      padding: 1rem .5rem 0; }
    display: flex;
    flex-flow: column nowrap;
    height: 100%; }
  .product-tag {
    position: absolute;
    right: 5px;
    top: 5px;
    display: flex;
    flex-flow: column nowrap;
    border-radius: 50%;
    padding: 1rem;
    font-size: 1rem !important;
    height: 3.75rem;
    width: 3.75rem;
    text-shadow: 3px 3px 2px #1f3412;
    z-index: 1; }
  .product-tag.featured {
    background-image: $featured-bg; }
  .product-quantity {
    &__list {
      li {
        div {
          &:not(:last-child) {
            margin-right: .5rem; } }
        align-items: baseline;
        margin: 0 0 1rem 0; }
      .field-body,
      .field {
        margin-bottom: 0; }
      .control {
        .icon {
          &.is-small {
            padding: .875rem .25rem; } }
        .help {
          position: absolute; }
        position: relative; }
      flex-flow: row wrap; } }
  .product-quickview-modal {
    @include touch {
      .modal-close {
        top: 95% !important;
        right: 45% !important; } } } }

.badge {
  .text {
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      text-shadow: none;
      margin-top: 3px; }
  .text.smaller {
      font-weight: 400;
      font-size: 10px;
      margin-top: -5px; } }
.product-description {
  @include touch {
    letter-spacing: .4px !important; } }
