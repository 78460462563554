.table-wrapper {
    .table {
        margin-bottom: 0;
    }
    &:not(:last-child) {
        margin-bottom: 1.5rem;
    }
    @include touch {
        overflow-x: auto;
    }
}

.b-table {
    transition: opacity $speed $easing;
    // Out of .table to work with the orderer <select> as well.
    .icon {
        transition: transform $speed-slow $easing, opacity $speed $easing;
        &.is-desc {
            transform: rotate(180deg);
        }
        &.is-expanded {
            transform: rotate(90deg);
        }
    }
    .table {
        width: 100%;
        // Transparent border to not break when focused
        // since box-shadow inset won't work correctly
        border: 0.125em solid transparent;
        border-radius: $radius;
        border-collapse: separate;
        th {
            font-weight: $weight-semibold;
            .th-wrap {
                display: flex;
                align-items: center;
                .icon {
                    margin-left: 0.5rem;
                    margin-right: 0;
                    font-size: 1rem;
                }
                &.is-numeric {
                    flex-direction: row-reverse;
                    text-align: right;
                    .icon {
                        margin-left: 0;
                        margin-right: 0.5rem;
                    }
                }
                &.is-centered {
                    justify-content: center;
                    text-align: center;
                }
            }
            &.is-current-sort {
                border-color: $grey;
                font-weight: $weight-bold;
            }
            &.is-sortable:hover {
                border-color: $grey;
            }
            &.is-sortable,
            &.is-sortable .th-wrap {
                cursor: pointer;
            }
        }
        tr {
            &.is-selected {
                .checkbox input {
                    &:checked + .check {
                        background: $table-row-active-color url(checkmark($table-row-active-background-color)) no-repeat center center;
                    }
                    + .check {
                        border-color: $table-row-active-color;
                    }
                }
            }
            &.is-empty:hover {
                background-color: transparent;
            }
            &:not(.is-selected).is-hovered {
                background: rgba($grey-light, 0.35) !important;
            }
        }
        .checkbox-cell {
            width: 40px;
            .checkbox {
                vertical-align: middle;
                .check {
                    transition: none;
                }
            }
        }
        .detail td {
            box-shadow: inset 0 1px 3px $grey-lighter;
            background: $white-bis;
            .detail-container {
                padding: 1rem;
            }
        }
        &:focus {
            border: 0.125em solid rgba($table-row-active-background-color, 0.5);
        }
        // Modifiers
        &.is-bordered {
            th.is-current-sort,
            th.is-sortable:hover {
                border-color: $grey-lighter;
                background: $background;
            }
        }
        &.has-mobile-cards {
            @include mobile {
                thead {
                    display: none;
                }
                tfoot {
                     th {
                        border: 0; // Disable is-bordered
                        display: inherit;
                     }
                }
                tr {
                    // Card style — Cannot extend inside media query
                    box-shadow: 0 2px 3px rgba($black, 0.1), 0 0 0 1px rgba($black, 0.1);
                    max-width: 100%;
                    position: relative;
                    // ---------------------------
                    display: block;
                    margin: 0.25em;
                    td {
                        border: 0; // Disable is-bordered
                        display: inherit;
                        &:last-child {
                            border-bottom: 0;
                        }
                    }
                    &:not(:last-child) {
                        margin-bottom: 1rem;
                    }
                    // Disables is-striped
                    &:not(.is-selected) {
                        background: inherit;
                        &:hover {
                            background-color: inherit;
                        }
                    }
                    &.detail {
                        margin-top: -1rem;
                    }
                }
                tr:not(.detail):not(.is-empty):not(.table-footer) {
                    td {
                        display: flex;
                        width: auto;
                        justify-content: flex-end;
                        text-align: right;
                        border-bottom: 1px solid $background;
                        &:before {
                            content: attr(data-label);
                            font-weight: $weight-semibold;
                            margin-right: auto;
                            padding-right: 0.5em;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }

    .level {
        padding-bottom: 1.5rem;
    }

    &.is-loading {
        position: relative;
        pointer-events: none;
        opacity: 0.5;
        &:after {
            @include loader;
            position: absolute;
            top: 4em;
            left: calc(50% - 2.5em);
            width: 5em;
            height: 5em;
            border-width: 0.25em;
        }
    }
}
