.category-card {
  .image-container {
    > img {
      min-height: rem(300px); } } }
.category-card,
.product-card {
  box-shadow: 0 0 0 0 transparent;
  .card-image {
    // &::after
    //   content: ''
    //   width: 80%
    //   height: 25%
    //   position: absolute
    //   left: 0
    //   right: 0
    //   bottom: 0
    //   box-shadow: 1px 1px 50px 0 #252e3a, 1px 1px 30px 0 rgba(131, 173, 84, .8)
    //   opacity: 0
    //   margin: 0 auto
    //   transform-style: preserve-3d
    //   transition: opacity .4s
    overflow: hidden;
    // transform: translate3d(0, 0, 0)
 }    // transition: all .3s
  .content {
    .subtitle > strong {
      font-weight: 600;
      color: $dark-green; } }
  .hidden-button, .button {
    width: 100%; }
  .hidden-button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate3d(-50%, 100%, 0);
    z-index: 2;
    transition: all 399ms; }
  .button {
    padding: 1.75rem 0;
    background-color: rgba(0, 43, 23, 0.8);
    border-color: transparent;
    color: #f2f2f2;
    font-variant: small-caps;
    font-size: 100%;
    letter-spacing: rem(7.5px);
    transition: color 399ms;
    &:hover {
      color: $yellow-greenery; } }
  &:hover {
    // .card-image
    //   &::after
    //     opacity: 1
    //   transform: translate3d(0, rem(-5px), 0)
    // box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.15)
    .leaf {
      transform: rotate(0); }
    .hidden-button {
      .button {
        border-radius: 0; }
      transform: translate3d(-50%, 0, 0); } }
  transition: all 399ms ease; }
// .box
//   > div,
//   > a
//       width: 100%
.address-card {
  &.address__create {
    .label {
      cursor: pointer;
      text-decoration: underline; }
    .box {
      display: flex;
      align-items: center;
      justify-content: center; } }
  .label {
    font-weight: 600; }
  .tooltip-prefer {
    .icon__fav {
      width: 50px; }
    position: absolute;
    right: 10px;
    top: 18px; }
  .subtitle {
    font-size: 90%; }
  padding-top: rem(20px); }
