.page-article {
  &__index {
    .panel-block {
      .title {
        text-transform: none;
        letter-spacing: .5px;
        margin-bottom: rem(5px); }
      span {
        color: rgba(0,0,0,.44);
        font-size: rem(14px); }
      padding: rem(25px) rem(15px) rem(20px) rem(15px);
      border-color: rgba(0,0,0,.05);
      border-top-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent; } } }
