// .full-height
//   height: 100vh

// .full-width {
// 	width: 100vw;
// }
.flex {
	display: flex; }

.is-flex {
  &.flex-end {
    justify-content: flex-end; } }

.flex-top-center {
	display: flex {
  align-items: flex-start;
  justify-content: center; } }

.flex-center {
	display: flex {
  align-items: center;
  justify-content: center; } }

.position {
	&-ref {
		position: relative;
  &-abs {
    position: absolute; } } }

.right {
	position: absolute;
	right: 0; }

.top-right {
  position: absolute;
  right: 10px;
  top: 18px; }

.clickable {
  cursor: pointer !important; }

.animated-short {
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.p- {
  &x-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  &y-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  &t-0 {
    padding-top: 0 !important; }
  &b-0 {
    padding-bottom: 0 !important; }
  &l-0 {
    padding-left: 0 !important; }
  &r-0 {
    padding-right: 0 !important; }
  &x-sm {
    padding-left: .5rem !important;
    padding-right: .5rem !important; }
  &y-sm {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important; }
  &t-sm {
    padding-top: .5rem !important; }
  &b-sm {
    padding-bottom: .5rem !important; }
  &l-sm {
    padding-left: .5rem !important; }
  &r-sm {
    padding-right: .5rem !important; }
  &x-1 {
    padding-left: 1rem !important;
    padding-right: 1rem !important; }
  &y-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  &t-1 {
    padding-top: 1rem !important; }
  &b-1 {
    padding-bottom: 1rem !important; }
  &l-1 {
    padding-left: 1rem !important; }
  &r-1 {
    padding-right: 1rem !important; }
  &x-2 {
    padding-left: 2rem !important;
    padding-right: 2rem !important; }
  &y-2 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  &t-2 {
    padding-top: 2rem !important; }
  &b-2 {
    padding-bottom: 2rem !important; }
  &l-2 {
    padding-left: 2rem !important; }
  &r-2 {
    padding-right: 2rem !important; } }

.m- {
  &x-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  &y-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  &t-0 {
    margin-top: 0 !important; }
  &b-0 {
    margin-bottom: 0 !important; }
  &l-0 {
    margin-left: 0 !important; }
  &r-0 {
    margin-right: 0 !important; }
  &x-sm {
    margin-left: .5rem !important;
    margin-right: .5rem !important; }
  &y-sm {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important; }
  &t-sm {
    margin-top: .5rem !important; }
  &b-sm {
    margin-bottom: .5rem !important; }
  &l-sm {
    margin-left: .5rem !important; }
  &r-sm {
    margin-right: .5rem !important; }
  &x-1 {
    margin-left: 1rem !important;
    margin-right: 1rem !important; }
  &y-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  &t-1 {
    margin-top: 1rem !important; }
  &b-1 {
    margin-bottom: 1rem !important; }
  &l-1 {
    margin-left: 1rem !important; }
  &r-1 {
    margin-right: 1rem !important; }
  &x-2 {
    margin-left: 2rem !important;
    margin-right: 2rem !important; }
  &y-2 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  &t-2 {
    margin-top: 2rem !important; }
  &b-2 {
    margin-bottom: 2rem !important; }
  &l-2 {
    margin-left: 2rem !important; }
  &r-2 {
    margin-right: 2rem !important; } }

.z {
  &-1 {
    z-index: 1; }
  &-0 {
    z-index: 0; } }

.fz- {
  &normal {
    font-size: 1rem !important; } }

.is-offset {
  &-minus- {
    &one-quarter {
      margin-left: -25%; }
    &one-third {
      margin-left: -33.33%; } } }

.is-text-limited {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }
