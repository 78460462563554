input,
textarea,
select {
  min-height: 1.75rem;
  outline: 0;
  background: transparent !important; }
.price-input,
.quantity-input {
  .field > .field:not(:last-child) {
    margin-right: .5rem; } }

input {
  &.is-small {
    + .icon.is-small {
      padding: .875rem .25rem !important; } } }

.intl-tel-input {
  width: 100%; }

.country.highlight {
  margin-bottom: 0px; }

.hide {
  display: none; }

.field-body {
  margin-bottom: .75rem; }

.input {
	&__search {
		input {
			border-radius: rem(25px); } } }

.control {
  &.is-loading {
    &:after {
      top: .325rem; } } }
