$list-height: rem(800px);
$inner-height: calc(#{$list-height} - 6rem);
.navigator-item {
  &.open {
    .list {
      max-height: $list-height; } } }
.list {
  // @media (min-height: 800px)
  //   height: 85vh
  // @media (min-height: 900px)
  //   height: 80vh
  overflow: hidden;
  padding-bottom: 3.5rem !important; }
.menu-list-wrapper {
  // @media (min-height: 800px)
  //   height: 85vh
  // @media (min-height: 900px)
  //   height: 80vh
  height: $inner-height;
  overflow-y: auto;
  overflow-x: hidden; }
.menu-list-header {
  align-items: center; }
.menu-list {
  &__mask {
    display: none; }
  .menu {
    min-height: 25vh;
    padding: 1rem 0; }
  .title {
    padding-top: .5rem;
    text-transform: uppercase;
    font-weight: 700;
    color: $yellow-greenery !important; }
  li {
    margin: .25rem 0; }
  ul {
    list-style: none;
    margin: .25rem 1rem 1rem 0rem;
    flex-flow: column wrap;
    justify-content: space-between;
    a {
      &.is-active {
        > * {
          color: $yellow-greenery !important; }
        background-color: transparent !important; }
      text-transform: capitalize;
      font-weight: 600;
      border-left: 5px solid transparent;
      padding: .35rem .5rem;
      transition: all 500ms ease; }
    li + li {
      margin-top: 0; } }
  position: relative;
  padding-left: 1.5rem;
  overflow-x: hidden; }
