.notification {
  display: none;
  position: fixed;
  opacity: 0;
  top: 0;
  right: 0;
  z-index: 1986;
  transform: translate3d(0, -100%, 0);
  &.show {
    display: block;
    transform: translate3d(0, 0, 0);
    animation: fadeInDown 1s .3s, fadeOutUp 1s 2s; } }
