.footer {
	p,
	a {
		color: $white-smoke; }
	.brand {
		letter-spacing: rem(1px); }
	.about {
		@include mobile {
			text-align: center; }
		font-size: 92.5%;
		word-spacing: rem(1px);
		max-width: rem(352px);
		line-height: rem(22px); }
	.navigation {
		.level-item {
			font-size: 85%;
			letter-spacing: rem(2px);
			padding: 0 .5rem; } }
	.reserve,
	.payment {
		@include mobile {
			font-size: 85%;
			text-align: center; } }
	hr {
		background-color: rgba(10, 10, 10, .1);
		margin: 2rem 0; }
	position: relative;
	padding: 3rem 2.5rem 2rem;
	.link__payment {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		img {
			font-size: 70%;
			position: relative;
			top: -7px;
			width: 45%;
			margin-left: .25rem; } } }
