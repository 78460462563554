h1,
h2,
h3,
h4,
h5,
h6,
.h6,
.h7,
.h8 {
  font-family: 'Source Sans Pro', sans-serif;
  text-transform: uppercase;
  font-weight: 700 !important;
  letter-spacing: 3px;
  color: $black-blue !important; }

h1 {
  font-size: 3.125rem; }
h2 {
  font-size: 2.618rem; }
h3 {
  font-size: 1.931rem; }
h4 {
  font-size: 1.618rem; }
h5 {
  font-size: 1.194rem; }
.h6 {
  font-size: 1rem; }
.h7 {
  font-size: .618rem; }
.h8 {
  font-size: .456rem; }

.content {
  h1 {
    font-size: 3.125rem; }
  h2 {
    font-size: 2.618rem; }
  h3 {
    font-size: 1.931rem; }
  h4 {
    font-size: 1.618rem; }
  h5 {
    font-size: 1.194rem; }
  .h6 {
    font-size: 1rem; }
  .h7 {}
  font-size: .618rem;
  .h8 {
    font-size: .456rem; }
  font-size: 1rem; }

.field {
  .label {
    font-weight: 700 !important; } }

p,
.subtitle {
  &.is-success {
    color: $greenery; }
  &.is-danger {
    color: $danger; }
  &.is-info {
    color: $info; }
  &.is-warning {
    color: $warning; } }

.title,
.subtitle {
  &.is-light {
    color: #f2f2f2 !important; } }
.page-title {
  color: $black-blue; }

.page-title-subtitle {
  p {
    letter-spacing: 1.1px; } }

.is-bold {
  font-weight: 600 !important; }
.is-nowrap {
  white-space: nowrap !important; }
.subtitle {
  font-size: 85%; }
.content {
  p {
    color: $default-text; } }
.has-text- {
  &regular {
    font-weight: 500 !important; }
  &no-underline {
    text-decoration: none !important; }
  &success {
    color: $success !important; }
  &grey-light {
    color: #ccc !important; }
  &tropicals {
    color: $tropicals-color !important; }
  &light {
    color: $light-text !important; } }

.is-size- {
  &7 {
    font-size: .75rem !important; } }

.has-text {
  &-gradient {
    &.is- {
      &tropicals {
        background-image: $tropicals; } }
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent !important;
 } }    // @media only screen and (min-width: 42em)
//   h1 {font-size: 6.2em;}
//   h2 {font-size: 4em;}
//   h3 {font-size: 3.125em;}
//   h4 {font-size: 2em;}
//   h5 {font-size: 1.563em;}
//   h6 {font-size: 1em;}
