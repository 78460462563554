.page-order {
  .order-status {
    a {
      pointer-events: none; } }
  .order-seller-note {
    .level-item,
    .content {
      width: 100%; }
    .label {
      font-size: .875rem;
      text-transform: uppercase;
      letter-spacing: 3px;
      margin-bottom: .25rem; }
    align-items: baseline; }
  .order-detail {
    h5 {
      &:first-child {
        margin-bottom: .25rem; } } }
  .b-table {
    td[data-label="ITEM"] {
      padding-top: .75rem; }
    th {
      font-size: 90%; }
    img {
      border-radius: 3px; }
    figure {
      margin: .5rem 0; } }
  .order-additional-price {
    .column {
      padding: 0rem .75rem; }
    .field-body {
      .field {
        max-width: 5.5rem;
        min-width: 4rem; }
      justify-content: flex-end; }
    .icon {
      &.is-small {
        padding: .975rem .25rem; } }
    .fa {
      font-size: 90%; }
    input {
      min-height: 1.75rem; } }
  .level-footer {
    .send-email {
      padding: 1.5rem; } }
  .order-card {
    padding-bottom: 1.75rem;
    margin-bottom: 5rem;
    .card-header {
      .level {
        &-left {
          flex-flow: row wrap;
          align-items: baseline;
          justify-content: space-between;
          .level-item {
            &:not(:last-child) {
              @include desktop {
                margin-right: 4rem; } } } }
        &-right {
          .level-item {
            &:not(:last-child) {
              margin-right: 0; }
            justify-content: flex-end; }
          @include mobile {
            margin-top: 0;
            .level-item {
              justify-content: center; } }
          flex-flow: column nowrap; } }
      .icon {
        &.is-small {
          padding: .6rem .25rem; } }
      justify-content: space-between;
      // display: flex
      // align-items: center
      flex-flow: column nowrap;
      padding: .75rem; }
    .card-footer {
      &-item {
        border-right: 0; }
      padding: .75rem;
      border-top: 0; }
    .order-date {
      padding-bottom: .25rem; }
    .order-address {
      margin-bottom: .5rem; }
    .order-note {
      .content {
        width: 100%;
        .order-note-content {
            background-color: #f2f2f2;
            padding: 1rem;
            margin-top: .25rem;
            margin-bottom: 2.5rem;
            min-height: 7.5rem; } } }
    .order-address,
    .order-seller-note {
      display: flex; }
    .level {
      &__user {
        .level-item {
          justify-content: center; }
        padding-top: 1.5rem;
        padding-bottom: 1.5rem; }
      align-items: flex-start; }
    // .level-left,
    // .level-right
    //   flex-flow: column nowrap
    //   align-items: stretch
    //   width: 50%
    .level-item {
      justify-content: flex-start; }
    .cta {
      &:not(:last-child) {
        margin-bottom: .75rem; } }
    .card-footer-item {
      .sub-order-details-actions {
        justify-content: center; } }
    //   +desktop
    //     &:not(:first-child)
    //       padding-left: 1.5rem
    //     &:not(:last-child)
    //       padding-right: 1.5rem
    //   +touch
    //     &:not(:last-child)
    //       border-right: 0
    //       border-bottom: 1px solid #dbdbdb
    //       padding-bottom: .75rem
    //       margin-bottom: .75rem
    //   flex-flow: column nowrap
    //   justify-content: flex-start
    //   padding: 0
 }    //   // align-items: flex-start
  .level-footer {
    .level-item {
      @include mobile {
        flex-flow: column nowrap;
        align-items: center; }
      &:last-child {
        @include mobile {
          padding-right: 0; }
        padding-right: 1rem; }
      align-items: baseline;
      justify-content: flex-end; } } }
.box {
  &__additional {
    padding: .85rem; }
  &__order-detail {
    .level {
      &-right {
        flex-flow: column nowrap; }
      @include mobile {
        text-align: center; }
      align-items: baseline; }
    .order-address {
      &:not(:last-child) {
 } }        // margin-right: 2rem
    .address-detail {
      .contact {
        .icon {
          cursor: default;
          margin-left: 0; }
        @include touch {
          justify-content: center; }
        align-items: baseline; }
      padding-top: .675rem; } }
  &__order-message {
    .order-note {
      .content {
        width: 100%; } }
    .label {
      letter-spacing: rem(3px); } }
  &__order-overall {
    .level {
      &-item {
        flex-flow: column nowrap; } } } }
.order {
  &__invoice {
    text-align: center;
    margin-bottom: .75rem; } }

.section__order-pay {
  .order-table__pay {
    .table {
      padding: 1rem;
      border-radius: .5rem;
      box-shadow: 0 0 8px 2px #e8e8e8; } }

  .tile__pay {
    box-shadow: 0 0 8px 2px #e8e8e8; } }
