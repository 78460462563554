html {
  &.is-clipped {
    overflow: hidden !important; } }

body {
  &.modal-open {
    &::-webkit-scrollbar {
      display: none; }
    overflow: hidden !important; }
  font: normal 100%/1.6 'Open Sans', sans-serif;
  font-weight: 400;
  color: $text-color;
  margin: 0 auto;
  text-align: left;
  position: relative; }

#bg {
  &__app {
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // background: url(/images/background-image-2.png) no-repeat center center fixed
    background: {
      repeat: no-repeat;
      position: right center;
      attachment: fixed;
      size: cover; } } }

// #app
//       box-shadow: 1px 1px 50px 2px rgba(54, 54, 54, 0.4), 2px 2px 20px 0px rgba(133, 206, 51, 0.5)
.logo {
  margin: 0 auto; }

.section {
  // &-header
  //   background-image: linear-gradient(120deg, #fdfbfb 0%, #f2f2f3 100%)
  // &-content
  //   background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)
  &-header {
    background-color: $body-bg-darker; }
  &-content {
    background-color: $body-bg-darker; } }

.section {
  @include touch {
    padding-left: 0;
    padding-right: 0;
    padding-top: 3rem; }
  padding: 1rem 2.5rem;
  position: relative; }

.select {
  &:after {
    z-index: 1 !important;
    margin-top: -.2rem; } }
.logo {
  color: $dark-green !important;
  font-weight: 700 !important;
  letter-spacing: .1rem;
  padding-bottom: 1.5rem;
  @include mobile {
    font-size: 1rem * $ms-ratio / 1.5 !important; }
  @include touch {
    font-size: 1rem * $ms-ratio;
    padding-bottom: 0; }
  & > .lead {
    font-weight: 400 !important;
    color: $greenery; } }

.icon {
  margin: 0 .25rem; }

.page-title {
  font-weight: 600; }

.content-section {
  padding: 0 1.5rem 1.5rem; }

.leaf {
  transform: rotate(90deg);
  transition: transform 300ms cubic-bezier(0.165, 0.840, 0.440, 1.000); }

.is-yellow-green {
  color: $yellow-greenery !important; }

.is-bg-yellow-green {
  background-color: $yellow-greenery !important;
  &:hover {
    background-color: darken($yellow-greenery, 2.5%) !important; } }

.is-tropicals {
  background-image: $tropicals;
  background-color: $white-smoke; }

.is-hover-pointer {
  cursor: pointer; }

.fa {
  transform: scale(1, 1);
  transition: transform 300ms;
  &:hover {
    transform: scale(1.1, 1.1); } }

.fa-1x {
  font-size: 1rem !important; }

.fa-2x {
  font-size: 2rem !important; }

.fa-3x {
  font-size: 3rem !important; }

.fa-4x {
  font-size: 4rem !important; }

.fa-5x {
  font-size: 5rem !important; }

.notices {
  &.is-toast {
    z-index: 1986; } }

.leaf {
  background: url(/images/leaf.svg) no-repeat !important; }

.next-button,
.prev-button {
  @include mobile {
    width: 100%; }
  width: 10rem; }

.select, {
  &.is-success select {
    border-color: $greenery; } }

.help, {
  &.is-success {
    color: $greenery; } }

.toast,
.snackbar {
  &.is-success {
    background-color: $yellow-greenery !important; } }

.continue-shop-link {
  color: $yellow-greenery; }

.b-table {
  th {
    font-weight: 700 !important;
    letter-spacing: 1px; } }

.field {
  label {
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 80%; } }

.bg__signup {
  background: url(/images/signup-bg.jpg) no-repeat center center fixed; }

.option {
  &.is-subheader {
    color: darken($light-gray, 5%);
    font-size: 80%; } }
.has- {
  &padding-less {
    padding: 0; }
  &padding-y-less {
    padding-top: 0;
    padding-bottom: 0; }
  &padding-x-less {
    padding-left: 0;
    padding-right: 0; } }

.dropdown {
  &-item {
    &--delete {
      &:hover {
        background-color: $danger !important;
        color: $white-smoke !important; }
      transition: all 499ms; }
    &.is-active {
      a {
        &.is-active {
          color: $lighter-grey; } } } } }

.swiper-pagination-bullets {
  .swiper-pagination-bullet {
    &-active {
      border-color: lighten($yellow-greenery, 10%);
      filter: contrast(110%);
      transform: scale3d(1.3, 1.3, 1);
      opacity: 1 !important; }
    overflow: hidden;
    opacity: .5;
    justify-content: center;
    border: 2px solid transparent;
    background-color: transparent;
    transition: all .3s ease-in; }
  display: inline-flex;
  justify-content: center; }
