.datepicker {
    font-size: 0.875rem;
    .dropdown,
    .dropdown-trigger {
        width: 100%;
    }
    .dropdown-item {
        font-size: inherit;
    }

    .datepicker-header {
        padding-bottom: 0.875rem;
        margin-bottom: 0.875rem;
        border-bottom: 1px solid $grey-lighter;
    }
    .datepicker-footer {
        padding-top: 0.875rem;
        border-top: 1px solid $grey-lighter;
    }

    .datepicker-table {
        display: table;
        margin: 0 auto 0.875rem auto;
        .datepicker-cell {
            text-align: center;
            vertical-align: middle;
            display: table-cell;
            border-radius: $radius;
            padding: 0.5rem 0.75rem;
        }
        .datepicker-header {
            display: table-header-group;
            .datepicker-cell {
                color: $grey;
                font-weight: $weight-semibold;
            }
        }
        .datepicker-body {
            display: table-row-group;
            .datepicker-row {
                display: table-row;
                .datepicker-cell {
                    &.is-unselectable {
                        color: $grey-light;
                    }
                    &.is-today {
                        border: solid 1px rgba($primary, 0.5);
                    }
                    &.is-selectable {
                        color: $grey-dark;
                        &:hover:not(.is-selected),
                        &:focus:not(.is-selected) {
                            background-color: $background;
                            color: $black;
                            cursor: pointer;
                        }
                    }
                    &.is-selected {
                        background-color: $primary;
                        color: $primary-invert;
                    }
                }
            }
        }
    }
    &.is-small {
        @include control-small;
    }
    &.is-medium {
        @include control-medium;
    }
    &.is-large {
        @include control-large;
    }
    @include tablet-only {
        .datepicker-table {
            .datepicker-cell {
                padding: 0.75rem 1rem;
            }
        }
    }
    @include mobile {
        .datepicker-table {
            .datepicker-cell {
                padding: 0.25rem 0.5rem;
            }
        }
    }
}
