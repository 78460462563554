$txt-color: #f2f2f2;
$error-color: #f2f2f2;

.quick-signup,
.modal__sign-in {
  input {
    &::placeholder {
      color: $txt-color; }
    &:focus {
      border-color: lighten(#65a0bd, 5%); }
    border-color: #65a0bd;
    color: $txt-color;
    transition: all 250ms ease; }
  .policy {
    small {
      font-size: .75rem; } }
  p,
  .fa {
    color: $txt-color !important; }
  .help,
  .icon {
    &.is-danger {
      color: $yellow-greenery !important; } }
  input {
    &.is-danger {
      border-color: $yellow-greenery; } } }
