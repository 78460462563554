.list-complete-item {
  	margin-top: 4px;
  	transition: all 699ms;
  	position: relative;
  	display: inline-flex;
  	z-index: 1; }
.list-complete-enter, .list-complete-leave-to {
	transform: translateY(30px);
	opacity: 0; }
.list-complete-leave-active {
	// position: absolute
	opacity: 0; }
