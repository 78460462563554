.icon {
  .fa {
    margin-right: .25rem; }
  &.is-small {
    width: 1rem !important;
    height: 1rem !important;
    padding: 0 .25rem; }
  &:hover {
  	cursor: pointer; } }

.control {
	&.has-icons-left,
	&has-icons-right {
		.icon {
			z-index: 1; } } }

.fa-eye {
  color: $tropicals-color;

  &-slash {
    color: $tropicals-color; } }
