.product-checker {
  .field {
    .control {
        max-width: 40%;
        margin-bottom: 0 !important; }
    .input,
    .select {
      border-radius: 0 !important; }
    position: relative;
    margin-bottom: 0 !important;
    .help {
      position: absolute;
      left: 0;
      bottom: rem(-30px); } }
  display: inline-flex;
  flex-flow: column wrap;
  position: relative;
  width: 100%;
  min-height: 5rem; }
