.user-controller {
	.dropdown-item {
		&.is-active {
			color: $white-smoke !important; }
		font-size: 100% !important;
		padding-right: 1rem; }
	.user-control-trigger {
		justify-content: space-between; }
	#logoutForm {
		button {
			color: $light-greenery; } } }
.menu {
	font-size: 100% !important;
	padding-right: 1rem !important; }
