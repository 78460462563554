@charset "utf-8";

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400|Source+Sans+Pro:700|Lora:400i);
@import "./font-awesome/scss/font-awesome";
@import "utilities/_all";
@import "~bulma";
@import "~buefy/src/scss/buefy";
@import "~dropzone/src/dropzone";
@import "base/_all";
@import "components/_all";
@import "layout/_all";
@import "~animate.css";
@import "~intl-tel-input/build/css/intlTelInput";
@import "~vue-form-wizard/dist/vue-form-wizard.min";
@import "./themify-icon/themify-icons";
