.level__navigator {
  z-index: 5; }
.nl-navigator {
  .navigator-item-detail {
    p:not(:last-child) {
      margin-bottom: 0; }
    .icon > .fa-sort-down {
      font-size: 1rem;
      position: relative;
      top: rem(-3.5px);
      cursor: pointer; }
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  display: flex;
  flex-direction: column;
  min-height: 3rem;
  .navbar-item {
    padding: .5rem 0 !important; }
  .list-icon .fa-sort-down {
    transform: rotate(0deg) translate3d(0, 0, 0);
    transform-origin: center;
    transition: all .3s ease; }
  .list {
    .title, .fa {
      color: $dark-green; }
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    min-width: 0vw;
    width: 0%;
    max-height: 0;
    padding: 2rem;
    background-color: $white-smoke;
    font-size: 100%;
    opacity: 0;
    box-shadow: 0 0 0 0 transparent;
    transform: translate3d(-5rem, -2rem, 0);
    transition: all 500ms ease, opacity 100ms; }
  .navigator-item:last-child {
      color: darken($separate-light-gray, 20%); }
  .navigator-item {
    .item {
      justify-content: space-around;
      align-items: center; }
    &:not(:last-child) {
      padding-right: .75rem;
      border-right: 1px solid $separate-light-gray; }
    z-index: 1;
    &.open {
      .list {
        @include mobile {
          min-width: 72.5vw; }
        @include tablet {
          min-width: 40vw; }
        @include fullhd {
          min-width: 35vw; }
        min-width: 30vw;
        width: 100%;
        visibility: visible;
        opacity: 1;
        box-shadow: 0 0px 25px 2px rgba(0, 0, 0, 0.15);
        transform: translate3d(-5rem, -2rem, 0); }
      .fa-sort-down {
        transform: rotate(-180deg) translate3d(0, -8px, 0); }
      .menu-list {
        &__mask {
          display: block;
          position: fixed;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0; } } } } }
