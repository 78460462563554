.item-list {
  .card {
    .card-image, .card-content {
 }      // border: rem(5px) solid $gainsboro
    .card-content {
      & > .content {
        p {
          @include touch {
            font-size: 90% !important; }
          color: $dark-green;
          font-size: 85%; }
        figure {
          margin: .25rem; }
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 2px; }
      & .subtitle {
        text-transform: capitalize;
        margin-bottom: .25rem; }
      margin-top: .75rem;
      padding: .5rem 0 0 0; }
    background-color: transparent; }
  margin-top: 1.5rem;
  padding: 1.5rem 0; }
.full-categories-list {
  @include touch {
    .list-complete-item {
      padding-left: 0 !important;
      padding-right: 0 !important; } } }
.category {
  @include touch {
    padding-left: 0 !important;
    padding-right: 0 !important; } }
.category-list-page {
  @include touch {
    padding-top: 0 !important; } }
