.pagination {
    .pagination-next,
    .pagination-previous {
        padding-left: 0.25em;
        padding-right: 0.25em;
        &.is-disabled {
            pointer-events: none;
            cursor: not-allowed;
            opacity: 0.5;
        }
    }
    &.is-simple {
        justify-content: normal;
    }
    .is-current {
        pointer-events: none;
        cursor: not-allowed;
    }
}
