.autocomplete {
    position: relative;
    .dropdown-menu {
        display: block;
        min-width: 100%;
        &.is-opened-top {
            top: auto;
            bottom: 100%;
        }
    }
    .dropdown-item {
        &.is-hovered {
            background: $dropdown-item-hover-background-color;
            color: $dropdown-item-hover-color;
        }
        &.is-disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
    &.is-small {
        @include control-small;
    }
    &.is-medium{
        @include control-medium;
    }
    &.is-large {
        @include control-large;
    }
}
