.panel {
    .panel-heading {
        &.is-collapsible {
            cursor: pointer;
        }
    }
    .panel-content {
        width: 100%;
    }
}
