.section-header {
  z-index: 4;
  @include touch {
    padding: 0; }
  @include mobile {
    justify-content: center; }
  position: relative;
  padding-bottom: 1.5rem;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start; }
.page-header {
  margin-bottom: 1rem; }
.heading {
  .columns {
    justify-content: center; } }
