$cart-header: rem(100px);
$cart-footer: rem(115px);
$cart-body: calc(100% - 100px - 115px);
.cart-container {
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1400;
  background: transparent; }
.cart-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(10, 10, 10, 0.86); }
.cart-mask {}
.cart {
  .cart-header,
  .cart-body,
  .cart-footer {
    padding: 0 rem(34px);
    position: relative; }
  .cart-header,
  .cart-footer {
    display: flex; }
  .cart-header {
    .title,
    .fa {
      color: white; }
    .icon {
      position: absolute;
      left: 5%;
      top: 50%;
      transform: translate(50%, -50%); }
    .fa {
      font-size: rem(21px); }
    height: $cart-header;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(225deg, #1f3412, rgba(31, 52, 18, .8)); }
  .cart-body {
    .cart-body-header {
      .button {
        width: 100%; }
      padding: rem(30px) 0; }
    .cart-body-content {
      flex: 1; }
    .cart-body-footer {
      .cart-subtotal {
        padding-top: rem(30px);
        padding-bottom: rem(30px); } }
    height: $cart-body;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between; }
  .cart-footer {
    .content {
      .button {
        width: 100%; }
      width: 100%; }
    border-top: 1px solid rgba(10, 10, 10, 0.1);
    padding-top: rem(30px);
    padding-bottom: rem(30px);
    height: $cart-footer; }
  @include mobile {
    width: 100vw;
    margin: 0; }
  @include tablet-only {
    width: 35vw; }
  @include desktop-only {
    width: 28vw; }
  display: flex;
  flex-flow: row wrap;
  height: 100vh;
  width: 25vw;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  margin-top: 0;
  background: linear-gradient(180deg, $white, lighten($white, 2.5%));
  z-index: 3; }

.cart-item-list,
.cart-table {
  .button {
    border-radius: 0; }
  .cart-item-update-container {
    .button:not(:last-child) {
      margin-right: .25rem; }
    display: flex;
    width: calc(100% - 1px); }
  padding-bottom: rem(30px);
  filter: blur(0);
  opacity: 1;
  transition: all 150ms ease-in;
  .quantity-custom-input {
    .input {
      margin-left: .2rem;
      min-height: .75rem;
      min-width: 3rem;
      max-width: 5rem; } }
  .item-box {
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0; }
    width: 90%;
    border-bottom: 1px solid rgba(10, 10, 10, 0.1);
    padding-bottom: rem(30px);
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem(30px); }
  .box {
    .media {
      &.is-loading {
        filter: blur(2px);
        opacity: .75; }
      &:hover {
        .media-right {
          opacity: 1;
 } }          // box-shadow: 1px 3px 3px rgba(10, 10, 10, 0.1)
      .media-right {
        @include touch {
          top: 67.5%;
          opacity: 1; }
        display: flex;
        opacity: 0;
        position: absolute;
        right: 0;
        top: -15%;
        border-radius: rem(25px);
        // box-shadow: 0 0 0 0 rgba(10, 10, 10, 0.1)
        transition: all 300ms linear; }
      .media-content {
        .tooltip {
          @include touch {
            width: rem(150px); }
          @include fullhd {
            width: rem(200px); }
          width: rem(75px); }
        font-size: 90%;
        margin-top: -.3rem; } }
    p {
      &.is-success {
        color: $greenery; } }
    position: relative;
    border-radius: 0;
    box-shadow: 0 0 0 0 rgba(10, 10, 10, 0.1);
    padding: 0;
 }    // transition: box-shadow 150ms ease-in
  align-content: flex-start; }

.cart-item-mask {
  &.is-loading {
    transform: scale3d(1,1,1); }
  p {
    color: rgba(10, 10, 10, 0.2); }
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale3d(0,0,1);
  transition: all 500ms ease-in-out; }

.page-my-cart {
  .b-table {
    .level {
      padding-bottom: 0; }
    .level-item {
      &:last-child {
        margin-right: 0; } } }
  .page-footer {
    .level {
      &:not(:last-child) {
        padding-bottom: 2.5rem; }
      width: 100%;
      align-items: flex-start; }
    .add-note-container {
      @include mobile {
        border-bottom: 1px solid #f5f5f5;
        padding-bottom: 1.5rem; } }
    .level-right {
      .sub-total {
        @include mobile {
          align-items: center; }
        flex-flow: column wrap;
        align-items: flex-end;
        justify-content: flex-start; }
      .cart-total-detail {
        @include touch {
          text-align: center; }
        text-align: right;
        font-style: italic; }
      flex-flow: column wrap;
      align-items: flex-end; }
    .footer-action {
      .level-right {
        @include mobile {
          width: 100%;
          margin: 0 auto; }
        width: 30%; }
      .content,
      .cta {
        width: 100%; } } } }
