.product-detail {
  @include touch {
    text-align: center;
    .content {
      text-align: center; }
    .price {
      justify-content: center !important;
      margin-bottom: .5rem; }
    .next-prev-level {
      justify-content: center; } }
  .box {
    border-radius: 0;
    background-image: linear-gradient(341deg, #ece9e6, #fff); }
  .next-prev-level {
    .level-left {
      padding-right: .175rem;
      border-right: 1px solid #dbdbdb; } }
  .swiper-box {
    .swiper-item {
      align-items: baseline !important; }
    height: auto; }
  .table {
    background-color: transparent; }
  .quick-view-header {
    .product-title {
      margin-right: 1rem; }
    .user-actions {
      .icon {
        cursor: pointer; }
      margin-top: rem(3px); }
    display: inline-flex; }
  .price-detail {
    padding-top: .5rem; }
  .price-title {
    line-height: rem(30px); }
  .message-title .subtitle,
  .content,
  .table {
    color: $tropicals-color !important; }
  .message {
    .level-right {
      .fa {
        position: relative;
        top: -5px; } }
    .message-title {
      margin-bottom: 1.0625rem;
      text-transform: uppercase;
      letter-spacing: .5px; }
    .message-body {
      padding-left: 0;
      padding-right: 0;
      border: none; }
    .content {
      font-family: 'Open Sans', Helvetica, sans-serif;
      padding-bottom: 1rem; }
    padding-top: 1.75rem;
    background-color: transparent; }
  .product-title,
  .price, {
    .title,
    .icon {
      color: $dark-green; } }
  .price-detail-title,
  .th-wrap,
  .message-title .subtitle,
  .product-checker .label {
    color: $earth-gray; }
  .product-title {
    .title {
      letter-spacing: 2px; } }
  .product-checker {
    margin-bottom: .55rem; }
  .price {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    padding: 0 0 .5rem 0;
    .subtitle {
      &:not(:last-child) {
        margin-right: .6rem; }
      font-size: rem(20px);
      line-height: rem(24px);
      margin-bottom: .1rem; } }
  .full-page-link {
    text-decoration: underline;
    font-size: .85rem;
    align-self: flex-end; }
  .product-checker {
    .label {
      font-weight: 400 !important; } }
  .icon {
    color: lighten($stroke-gray, 10%);
    margin: 0 -.1rem 0 .25rem; }
  .content {
    &.content__modal {
      height: 100%; }
    .b-table {
      margin-top: -.65rem;
      font-size: .85rem;
      th,
      td {
        > span {
          display: flex;
          align-items: center;
          justify-content: center; }
        text-align: center;
        vertical-align: middle; }
      .th-wrap {
        .icon {
          display: none; }
        justify-content: center;
        font-weight: 400; }
      tr {
        padding: 1rem; }
      td {
        > span {
          color: $stroke-gray; }
        padding: .2rem .2rem; } } } }
