.page {
	&-article {
		#sidebar-controls {
			margin-top: 0 !important; } }
	&__plant-search-service,
	&__plant-tour,
	&__about-us {
		.editor__status {
			&.status {
				&__inline {
					position: absolute !important;
					left: 1.5625rem !important; } } } }
	&-pay-success {
		.icon__check > div {
			width: 25%;
			height: 25%;
			margin: 0 auto;
			cursor: default; } } }
