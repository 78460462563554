.page {
  &__blog-index {
    @include mobile {
      padding-top: 2rem; }
    .page {
      &-header {
        margin-bottom: 4.5rem; } } } }
.blog {
  margin-bottom: rem(25px);
  &__media {
    @include desktop {
      font-size: rem(15px) !important; }
    margin-top: -.2rem;
    margin-bottom: .75rem; }
  &__title {
    @include desktop {
      font-size: 1.3rem !important; }
    text-transform: capitalize; }
  &__subtitle {
    margin-top: rem(7px);
    margin-bottom: .2rem !important;
    font-weight: 500 !important;
    text-transform: none; }
  &__title,
  &__subtitle {
    letter-spacing: 0; }
  &__posted,
  &__created-by {
    .icon {
      padding: .25rem .75rem .25rem 0; }
    align-items: baseline; }
  &__posted {
    margin-right: 1.5rem; }
  &__hero {
    background: url(/images/cover/blog.png) no-repeat center center fixed;
    background-size: contain; }
  &__cover {
    &:hover {
      box-shadow: 0px 0px 10px 0px rgba(74, 74, 74, 0.2); }
    background-color: #f7f7f7;
    border-radius: 2px;
    box-shadow: 0px 0px 10px 0px rgba(156, 156, 156, 0.2);
    transition: all 499ms; }
  &__over-all {
    padding: 1.25rem; }
  &__content {
    margin-top: 1.25rem; }
  &__action {
    padding-top: .75rem; }
  .action {
    &__like {
      color: $yellow-greenery; } }
  &__readmore {
    a {
      color: rgba(0,0,0,.44);
      text-decoration: none;
      font-size: 75%; } }
  &__list {
    li {
      &:not(:first-child) {
        padding-top: rem(20px); }
      &:not(:last-child) {
 } } } }        // border-bottom: 1px solid rgba(0,0,0,.0785)
.media {
  .content {
    .sub {
      font-size: 75%; } } }
