.cart-table,
.order-table {
  // .table-wrapper
  //   max-height: 44.5vh;
  .th-wrap {
    font-size: 90%;
    font-weight: 600; }
  td {
    &[data-label="PRODUCT"] {
      width: calc(100% / 4 - 5%); }
    &[data-label="ITEM"] {
      .subtitle {
        margin-bottom: .5rem; } }
    width: calc(100% / 4);
    padding: rem(30px) rem(10px); }
  .level-left {
    .fa {
      margin-top: rem(-15px); }
    @include touch {
      opacity: 1; }
    display: flex;
    opacity: 0;
    transition: all 300ms linear; }
  tr {
    &:last-child {
      border-bottom: 1px solid #dbdbdb; }
    &:hover {
      .level-left {
          opacity: 1; } } }
  width: 100%; }

