.search-panel {
  .sub-title,
  .icon > .fa {
    color: lighten($dark-green, 5%); }
  .sub-title {
    text-transform: lowercase; }
  .icon > .fa {
    font-size: 1rem / $ms-ratio * 1.5; }
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  padding: .75rem 1.25rem !important;
  background-color: $yellow-greenery; }
