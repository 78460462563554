/**
 * Image Container Section
 */
.image {
  &.is-80x80 {
    height: rem(80px);
    width: rem(80px); }
  &.is-152x152 {
    height: rem(152px);
    width: rem(152px); }
  &.is-256x256 {
    height: rem(256px);
    width: rem(256px); }
  &.is-height172 {
    height: rem(172px) !important; }
  &--background {
    background-position: 50% 50% !important;
    background-origin: border-box !important;
    background-size: cover !important; } }
.image-container {
  position: relative;
  overflow: hidden;
  .after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    color: white;
    background: rgba(0, 0, 0, .25);
    transition: all .3s; }
  img {
    transition: all .3s; }
  .zoom {
    .fa {
      padding: .25rem;
      margin: auto 0; }
    color: #DDD;
    position: absolute;
    cursor: pointer;
    transition: all .3s; }
  .content {
    position: absolute;
    bottom: 0;
    font-family: Arial;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    color: white; } }
/**
 * Hover Area
 */
.card:hover {
  .after {
    background: rgba(0, 0, 0, .6); }
  img {
 }    // transform: scale(1.1,1.1)
  .zoom {
    color: #FFF; } }

/**
 * intl-input-input library's iamge
 */
.iti-flag {
  background-image: url("../../img/flags.png"); }

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .iti-flag {
    background-image: url("../../img/flags@2x.png"); } }
